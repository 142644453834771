import { FC } from 'react';
import classNames from 'classnames';
import {
    Scheduler,
    MonthView,
    WeekView,
    DayView,
    Toolbar,
    DateNavigator,
    Appointments,
    TodayButton,
    AllDayPanel,

} from '@devexpress/dx-react-scheduler-material-ui';
import {
    ViewState,
    EditingState,
    IntegratedEditing,
} from '@devexpress/dx-react-scheduler';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

import AppointmentItem from './AppointmentItem';
import DayList from './DayList';
import CreateScheduleDialog from '../CreateScheduleDialog';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';
import {
    AllDayViewTimeTableCell,
    AllDayViewTimeTableRow,
    DayViewDayScaleCell,
    DayViewTimeTableCell,
    MonthViewDayScaleCell,
    MonthViewTimeTableCell,
    WeekViewDayScaleCell,
    WeekViewTimeTableCell,
} from './Components';
import useSchedulerBox, { Props, ReceivedProps, SWITCHER_LIST } from './hook';
import { SchedulerBoxWrapper } from './style';
import PlanUpdate from 'pages/project/projectPlan/components/planUpdate';
import ProjectPlanDialog from '../ProjectPlanDialog';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

const SchedulerBoxLayout: FC<Props> = ({
    loading,
    showDayList,
    currentViewName,
    currentDate,
    localScheduleDate,
    scheduleList,
    openScheduleFormDialog,
    openDeleteConfirmationDialog,
    openPlanUpdateModal,
    workerOptions,
    activeTab,
    deleteSchedule,
    fetchSchedules,
    setCurrentItemId,
    setOpenDeleteConfirmationDialog,
    commitChanges,
    setCurrentDate,
    setShowDayList,
    setCurrentViewName,
    setOpenScheduleFormDialog,
    setOpenPlanUpdateModal,
    isShowData,
    setIsShowData
}) => {
    if (loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'center',
                }}
            >
                <CircularProgress size={16} />
            </Box>
        );
    }

    const onChange = (e: CheckboxChangeEvent) => {
        setIsShowData(e.target.checked)
        localStorage.setItem("showData", `${e.target.checked}`);
    };

    return (
        <>
            {openScheduleFormDialog && (
                <CreateScheduleDialog
                    fetchSchedules={fetchSchedules}
                    open={openScheduleFormDialog}
                    openDeleteConfirmationDialog={openDeleteConfirmationDialog}
                    setOpen={setOpenScheduleFormDialog}
                    setOpenDeleteConfirmationDialog={
                        setOpenDeleteConfirmationDialog
                    }
                    activeTab={activeTab}
                    setCurrentItemId={setCurrentItemId}
                    workerOptions={workerOptions || []}
                />
            )}

            {
                openPlanUpdateModal && (
                    <ProjectPlanDialog
                        open={openPlanUpdateModal}
                        setOpen={setOpenPlanUpdateModal}
                        fetchSchedules={fetchSchedules}
                    />
                )
            }


            {openDeleteConfirmationDialog && (
                <DeleteConfirmationDialog
                    open={openDeleteConfirmationDialog}
                    setOpen={setOpenDeleteConfirmationDialog}
                    onClick={deleteSchedule}
                />
            )}

            <SchedulerBoxWrapper>
                <div
                    className={classNames(
                        'schedulerWrapper',
                        showDayList ? 'hideMainTable' : '',
                    )}
                >
                    <div className="customViewSwitcher">
                        <Checkbox onChange={onChange} checked={isShowData}>TODO</Checkbox>
                        {SWITCHER_LIST.map((item) => (
                            <button
                                className={classNames(
                                    item.id === currentViewName && !showDayList
                                        ? 'active'
                                        : '',
                                )}
                                key={item.id}
                                type="button"
                                onClick={() => {
                                    setShowDayList(false);
                                    setCurrentViewName(item.id);
                                }}
                            >
                                {item.name}
                            </button>
                        ))}

                        <button
                            type="button"
                            className={classNames(showDayList ? 'active' : '')}
                            onClick={() => {
                                setShowDayList(true);
                                setCurrentViewName('Day');
                                if (!currentDate) {
                                    setCurrentDate(new Date());
                                }
                            }}
                        >
                            リスト
                        </button>
                    </div>

                    <Scheduler data={isShowData ? localScheduleDate : []} locale="ja-JP">
                        <ViewState
                            defaultCurrentDate={(() => new Date())()}
                            currentDate={currentDate}
                            currentViewName={currentViewName}
                            onCurrentDateChange={(date) => setCurrentDate(date)}
                        />
                        <EditingState onCommitChanges={commitChanges} />
                        <IntegratedEditing />
                        <WeekView
                            startDayHour={0}
                            endDayHour={24}
                            timeTableCellComponent={WeekViewTimeTableCell}
                            dayScaleCellComponent={WeekViewDayScaleCell}
                        />
                        <MonthView
                            timeTableCellComponent={MonthViewTimeTableCell}
                            dayScaleCellComponent={MonthViewDayScaleCell}
                        />
                        <DayView
                            timeTableCellComponent={DayViewTimeTableCell}
                            dayScaleCellComponent={DayViewDayScaleCell}
                        />
                        <AllDayPanel
                            cellComponent={AllDayViewTimeTableCell}
                            rowComponent={AllDayViewTimeTableRow}
                            messages={{ allDay: '終日' }}
                        />
                        <Toolbar />
                        <TodayButton messages={{ today: '今日' }} />
                        <DateNavigator />
                        <Appointments
                            appointmentComponent={({ ...restProps }) => (
                                <AppointmentItem
                                    restProps={restProps}
                                    setOpenScheduleFormDialog={
                                        setOpenScheduleFormDialog
                                    }
                                    setOpenPlanUpdateModal={setOpenPlanUpdateModal}
                                    currentViewName={currentViewName}
                                    setCurrentViewName={setCurrentViewName}
                                    setCurrentDate={setCurrentDate}
                                />
                            )}
                        />
                    </Scheduler>

                    {showDayList && (
                        <DayList
                            data={isShowData ? scheduleList.data : []}
                            currentDate={currentDate}
                            setOpenScheduleFormDialog={
                                setOpenScheduleFormDialog
                            }
                        />
                    )}
                </div>
            </SchedulerBoxWrapper>
        </>
    );
};

const SchedulerBox: FC<ReceivedProps> = (props) => (
    <SchedulerBoxLayout {...useSchedulerBox(props)} />
);

export default SchedulerBox;
