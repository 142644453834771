import { useState, useEffect, FocusEventHandler, useRef, FC } from 'react';
import './workerCreate.css';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { RootState, AppDispatch, useAppDispatch } from '../../../store/index';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import createWorkerValidation from 'utils/validation/registers/workers/createWorkerValidation';
import { useTranslation } from 'react-i18next';
import { RequireSign } from 'components/common';
import moment from 'moment';
import { DatePicker, Input, message, Select, Upload } from 'antd';
import { BANK_ACCOUNT_TYPES, RELATIONSHIP_OPTIONS, UNIFORM_SIZES } from 'utils/constants';
import { fullWidthNumConvert } from 'pages/invoice';
import { formatZipcode } from 'utils/helper/workerHelper';
import { StyledFormGroup, StyledFormGroupLabel } from '../style';
import { PlusOutlined } from '@ant-design/icons'
import useWorkerForm, { Props, ReceivedProps } from './hook';
import { MAX_LENGTH, TYPE_OF_SCREEN } from 'pages/project/projectPlan/projectTabs/constant';

const WorkerCreateLayout: FC<Props> = ({
    handleSubmit,
    submitData,
    control,
    dragProps,
    dummyRequest,
    setValue,
    register,
    clearErrors,
    watch,
    getValues,
    setError,
    errors,
    navigate,
    fileLength,
    isAllowUpload,
    listFile,
    setFileLength,
    setAllowUpload,
    setListFile,
    totalImageSize,
    setTotalImageSize,
    handleValidateWhitespace,
    screenType,
    positionOption,
    indexError,
    departmentOption,
    sizeUploadLimitError,
    listGenderValue,
    deleteError,
    fileList,
    previewOpen,
    previewImage,
    previewTitle,
    handleRemove,
    handleChangeFile,
    handleBeforeUpload,
    parsePostalCode,
    handlePreview
}) => {
    const { t } = useTranslation();

    const { positionsList } = useSelector(
        (state: RootState) => state.positions,
    );
    const { departmentList } = useSelector(
        (state: RootState) => state.deparments,
    );
    const [companyOption, setCompanyOption] = useState<any>([]);
    const findZipcodeTimer = useRef<NodeJS.Timeout>();

    const UPLOAD_LIMIT = 50;

    const uploadButton = (
        <div className="flex flex-col items-center">
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                ァイルを選択
            </div>
        </div>
    )
    const listGender = [
        {
            label: "男性",
            value: 'male',

        },
        {
            label: "女性",
            value: 'female',

        },
        {
            label: "その他",
            value: 'other',

        },

    ]
    return (
        <form
            onSubmit={handleSubmit(submitData)}
            className="container-worker-create-detail d-flex flex-row"
        >
            <div className='worker-content ' >
                <div className="grid grid-cols-4 gap-x-[15px]">
                    <div className="input-add-user margin-input d-flex flex-column">
                        <p>社員コード</p>
                        <input
                            autoComplete="off"
                            type="text"
                            className="worker-create-input !bg-[#F5F5F5]"
                            style={{ border: '1px solid #D9D9D9' }}
                            disabled
                            {...register('code')}
                        // disabled
                        />
                        {errors.code && (
                            <span className="error">
                                {errors.code.message?.toString()}
                            </span>
                        )}
                    </div>
                    <div className="input-add-user margin-input d-flex flex-column">
                        <p><RequireSign /> 名前</p>
                        <input
                            autoComplete="off"
                            type="text"
                            className="worker-create-input"
                            maxLength={MAX_LENGTH}
                            {...register('name', createWorkerValidation(t).name())}
                            onBlur={handleValidateWhitespace}
                        />
                        {errors.name && (
                            <span className="error">
                                {errors.name.message?.toString()}
                            </span>
                        )}
                    </div>
                    <div className="input-add-user margin-input d-flex flex-column">
                        <p><RequireSign />  フリガナ</p>
                        <input
                            autoComplete="off"
                            type="text"
                            className="worker-create-input"
                            {...register('furigana_name', createWorkerValidation(t).kana())}
                            onBlur={handleValidateWhitespace}
                            maxLength={MAX_LENGTH}
                        />
                        {errors.furigana_name && (
                            <span className="error">
                                {errors.furigana_name.message?.toString()}
                            </span>
                        )}
                    </div>
                    <div className="input-add-user margin-input d-flex flex-column">
                        {/* <p style={{ marginTop: 0 }}>性別</p> */}
                        {/* <Controller
                            name="gender"
                            control={control}
                            render={({ field: { value } }) => (
                                <Dropdown
                                    className="dropdown-create-user1 worker-create-input"
                                    controlClassName="dropdown-control-user"
                                    options={listGender}
                                    value={{
                                        label: value?.label || '',
                                        value: value?.value || '',
                                    }}
                                    {...register(
                                        'gender',
                                        // createWorkerValidation(t).gender()
                                    )}
                                    onChange={(newValue) => {
                                        if (
                                            value &&
                                            value.value === newValue.value
                                        ) {
                                            setValue('gender', undefined);
                                        } else {
                                            setValue('gender', newValue?.value);
                                        }
                                    }}
                                    placeholder=""
                                />
                            )}
                        /> */}
                        <Controller
                            name="gender"
                            control={control}
                            render={({ field }) => (
                                <div className='!w-[100%] !h-[40px]'>
                                    <p className="text-[14px] mb-[4px] font-medium ">
                                        性別
                                    </p>
                                    <Select
                                        options={listGender}
                                        {...register(
                                            'gender'
                                        )}
                                        value={field.value}
                                        onChange={(value) => {
                                            field.onChange(value)
                                        }}
                                        className='!w-[100%] !h-[40px]'
                                    />
                                    <br />
                                    {errors.gender && (
                                        <span className="error">
                                            {errors.gender.message?.toString()}
                                        </span>
                                    )}
                                </div>

                            )}
                        />
                        {errors.gender && (
                            <span className="error">
                                {errors.gender.message?.toString()}
                            </span>
                        )}
                    </div>
                    <div className='flex flex-row' >
                        <Controller
                            name="birth_date"
                            control={control}
                            render={({ field }) => (
                                <div className="!w-[85%]">
                                    <p className="text-[14px] mb-[4px] font-medium ">
                                        生年月日
                                    </p>
                                    <DatePicker
                                        {...register(
                                            'birth_date'
                                        )} {...field} format={'YYYY年MM月DD日'}
                                        onBlur={handleValidateWhitespace}
                                        value={field.value ? moment(field.value) : null}
                                        onChange={(date) => {
                                            field.onChange(date)

                                            setValue('age', date ? moment().diff(date, 'years') : 0)
                                        }}
                                        className='rounded-sm'
                                        placeholder='yyyy年mm月dd日' style={{ height: '36px', width: '100%' }} />
                                    <br />
                                    {errors.birth_date && (
                                        <span className="error">
                                            {errors.birth_date.message?.toString()}
                                        </span>
                                    )}
                                </div>

                            )}
                        />

                        <div className='ml-[15px]' >
                            <p className="text-[14px] mb-[4px] font-medium ">年齢</p>
                            <input
                                autoComplete="off"
                                disabled
                                type="text"
                                className='!w-[50px] h-[36px] text-center bg-[#F5F5F5] rounded-sm ' style={{ border: '1px solid #D9D9D9' }}
                                {...register('age')}
                            />
                            {errors.age && (
                                <span className="error">
                                    {errors.age.message?.toString()}
                                </span>
                            )}
                        </div>
                    </div>


                    <div className="input-add-user margin-input d-flex flex-column">
                        <p style={{ marginTop: 0 }}>役職</p>
                        {/* <Controller
                            name="position_id"
                            control={control}
                            render={({ field: { value } }) => (
                                <Dropdown
                                    className="dropdown-create-user1 worker-create-input"
                                    controlClassName="dropdown-control-user"
                                    options={positionOption}
                                    value={{
                                        label: value?.label || '',
                                        value: value?.value || '',
                                    }}
                                    {...register(
                                        'position_id',
                                        // createWorkerValidation(t).position_id()
                                    )}
                                    onChange={(newValue) => {
                                        if (
                                            value &&
                                            value.value === newValue.value
                                        ) {
                                            setValue('position_id', undefined);
                                        } else {
                                            setValue('position_id', newValue);
                                        }
                                    }}
                                    placeholder=""
                                />
                            )}
                        /> */}
                        <input
                            autoComplete="off"
                            type="text"
                            className="worker-create-input"
                            {...register('role')}
                            onBlur={handleValidateWhitespace}
                            maxLength={MAX_LENGTH}
                        />
                        {errors.role && (
                            <span className="error">
                                {errors.role.message?.toString()}
                            </span>
                        )}
                    </div>

                    <div className="input-add-user margin-input d-flex flex-column">
                        <p style={{ marginTop: 0 }}>部署</p>
                        {/* <Controller
                            name="department_id"
                            control={control}
                            render={({ field: { value } }) => (
                                <Dropdown
                                    className="dropdown-create-user1 worker-create-input"
                                    controlClassName="dropdown-control-user"
                                    options={departmentOption}
                                    value={{
                                        label: value?.label || '',
                                        value: value?.value || '',
                                    }}
                                    {...register(
                                        'department_id',
                                        // createWorkerValidation(t).department_id()
                                    )}
                                    onChange={(newValue) => {
                                        if (
                                            value &&
                                            value.value === newValue.value
                                        ) {
                                            setValue('department_id', undefined);
                                        } else {
                                            setValue('department_id', newValue);
                                        }
                                    }}
                                    placeholder=""
                                />
                            )}
                        /> */}
                        <input
                            autoComplete="off"
                            type="text"
                            className="worker-create-input"
                            {...register('department')}
                            onBlur={handleValidateWhitespace}
                            maxLength={MAX_LENGTH}
                        />
                        {errors.department && (
                            <span className="error">
                                {errors.department.message?.toString()}
                            </span>
                        )}
                    </div>

                    <Controller
                        name="entry_date"
                        control={control}
                        render={({ field }) => (
                            <div>
                                <p className="text-[14px] mb-[4px] font-medium ">
                                    入社日
                                </p>
                                <DatePicker
                                    {...register(
                                        'entry_date'
                                    )} {...field} format={'YYYY年MM月DD日'}
                                    onBlur={handleValidateWhitespace}
                                    value={field.value ? moment(field.value) : null}
                                    onChange={(date) => {
                                        field.onChange(date)
                                    }}
                                    className='rounded-sm'
                                    placeholder='yyyy年mm月dd日' style={{ height: '36px', width: '100%' }} />
                                <br />
                                {errors.entry_date && (
                                    <span className="error">
                                        {errors.entry_date.message?.toString()}
                                    </span>
                                )}
                            </div>

                        )}
                    />

                    <Controller
                        name="leaving_date"
                        control={control}
                        render={({ field }) => (
                            <div>
                                <p className="text-[14px] mb-[4px] font-medium ">
                                    退社日
                                </p>
                                <DatePicker
                                    {...register(
                                        'leaving_date'
                                    )} {...field} format={'YYYY年MM月DD日'}
                                    onBlur={handleValidateWhitespace}
                                    value={field.value ? moment(field.value) : null}
                                    onChange={(date) => {
                                        field.onChange(date)
                                    }}
                                    className='rounded-sm'
                                    placeholder='yyyy年mm月dd日' style={{ height: '36px', width: '100%' }} />
                                <br />
                                {errors.leaving_date && (
                                    <span className="error">
                                        {errors.leaving_date.message?.toString()}
                                    </span>
                                )}
                            </div>

                        )}
                    />

                    {/* <div className="input-add-user margin-input d-flex flex-column">
                        <p style={{ marginTop: 0 }}>制服サイズ</p>
                        <input
                            autoComplete="off"
                            type="text"
                            className="worker-create-input"
                            {...register(
                                'uniform_size',
                            )}
                        />
                        {errors.uniform_size && (
                            <span className="error">
                                {errors.uniform_size.message?.toString()}
                            </span>
                        )}
                    </div> */}

                    <Controller
                        name="uniform_size"
                        control={control}
                        render={({ field }) => (
                            <div className='!w-[100%] !h-[40px]'>
                                <p className="text-[14px] mb-[4px] font-medium ">
                                    制服サイズ
                                </p>
                                <Select
                                    options={UNIFORM_SIZES}
                                    {...register(
                                        'uniform_size'
                                    )}
                                    value={field.value}
                                    onChange={(value) => {
                                        field.onChange(value)
                                    }}
                                    className='!w-[100%] !h-[40px]'
                                />
                                <br />
                                {errors.uniform_size && (
                                    <span className="error">
                                        {errors.uniform_size.message?.toString()}
                                    </span>
                                )}
                            </div>

                        )}
                    />

                    <Controller
                        name="insurance"
                        control={control}
                        render={({ field }) => (
                            <div className='!w-[100%] !h-[40px]'>
                                <p className="text-[14px] mb-[4px] font-medium ">
                                    工事保険加入
                                </p>
                                <Select
                                    options={[
                                        { value: true, label: '有' },
                                        { value: false, label: '無' },
                                    ]}
                                    {...register(
                                        'insurance'
                                    )}
                                    value={field.value}
                                    onChange={(value) => {
                                        field.onChange(value)
                                    }}
                                    className='!w-[100%] !h-[40px]'
                                />
                                <br />
                                {errors.insurance && (
                                    <span className="error">
                                        {errors.insurance.message?.toString()}
                                    </span>
                                )}
                            </div>

                        )}
                    />

                    <div className="flex flex-col w-full">
                        <Controller
                            name="postal_code"
                            control={control}
                            render={({ field, fieldState }) => {
                                const { name, onChange, onBlur, ref } =
                                    register(
                                        'postal_code',
                                        // createWorkerValidation(t).zipcode(),
                                    );
                                return (
                                    <div className="d-flex flex-column">
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            郵便番号
                                        </p>
                                        <div className="flex gap-[10px]">
                                            <Input
                                                placeholder="000-0000"
                                                className='!h-[36px] !w-[100%]'
                                                {...register('zipCode')}
                                                onBlur={(evt) => {
                                                    let string = formatZipcode(evt.target.value)
                                                    if (
                                                        string.length > 4 &&
                                                        string.indexOf('-',) !== 3
                                                    ) {
                                                        let txt2 = string.replaceAll('-', '',);
                                                        txt2 = string.slice(0, 3,) + '-' + string.slice(3,);
                                                        setValue('postal_code', txt2,);
                                                    } else {
                                                        setValue('postal_code', string,);
                                                    }
                                                }}
                                                maxLength={8}
                                                value={field.value}
                                                onChange={(evt) => {
                                                    field.onChange(evt.target.value,);
                                                    setValue('postal_code', evt.target.value,);
                                                    clearErrors('postal_code',);
                                                }}
                                            />
                                            <button
                                                onClick={() => {
                                                    if (fieldState.error) {
                                                        return;
                                                    }
                                                    parsePostalCode(getValues('postal_code',) as string,
                                                    );
                                                }}
                                                type="button"
                                                className="border-[1px] px-[10px] w-[130px] whitespace-nowrap rounded-[3px] text-[white] bg-[#215493] border-[#215493] !text-[16px]"
                                            >
                                                〒検索
                                            </button>
                                        </div>
                                        {errors.postal_code && (
                                            <span className="error !text-[14px]">
                                                {errors.postal_code.message?.toString()}
                                            </span>
                                        )}
                                    </div>
                                );
                            }}
                        />
                    </div>

                    <div className="input-add-user mt-[15px] margin-input d-flex flex-column">
                        <p> 住所１</p>
                        <input
                            autoComplete="off"
                            type="text"
                            className="worker-create-input"
                            {...register('address1')}
                            onBlur={handleValidateWhitespace}
                            maxLength={MAX_LENGTH}
                        />
                        {errors.address1 && (
                            <span className="error">
                                {errors.address1.message?.toString()}
                            </span>
                        )}
                    </div>

                    <div className="input-add-user mt-[15px] margin-input d-flex flex-column">
                        <p> 住所２</p>
                        <input
                            autoComplete="off"
                            type="text"
                            className="worker-create-input"
                            {...register('address2')}
                            onBlur={handleValidateWhitespace}
                            maxLength={MAX_LENGTH}
                        />
                        {errors.address2 && (
                            <span className="error">
                                {errors.address2.message?.toString()}
                            </span>
                        )}
                    </div>

                    <div className="input-add-user mt-[15px] margin-input d-flex flex-column">
                        <Controller
                            name="mobile_number"
                            rules={{ required: true }}
                            control={control}
                            render={({ field }) => {
                                const { name, onChange, onBlur, ref } =
                                    register(
                                        'mobile_number',
                                        createWorkerValidation(t).celphone(),
                                    );
                                return (
                                    <div className="d-flex flex-column w-full">
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            <RequireSign /> 携帯番号
                                        </p>
                                        <input
                                            maxLength={12}
                                            {...register('mobile_number')}
                                            onBlur={(evt) => {
                                                setValue('mobile_number', fullWidthNumConvert(evt.target.value));
                                            }}
                                            className="worker-create-input"
                                            value={field.value}
                                            onChange={(evt) => {
                                                let stringWithoutCommas = evt.target.value.replace(/,/g, '',);
                                                if (stringWithoutCommas.match('^[0-9０-９]+$',) || stringWithoutCommas === '') {
                                                    if (stringWithoutCommas.length <= 12) {
                                                        const newVal = fullWidthNumConvert(stringWithoutCommas,);
                                                        field.onChange(stringWithoutCommas,);
                                                        setValue('mobile_number', stringWithoutCommas,);
                                                    }
                                                }
                                            }}
                                        />
                                        {errors.mobile_number && (
                                            <span className="error text-xs tracking-tight mt-1">
                                                {errors.mobile_number.message?.toString()}
                                            </span>
                                        )}
                                    </div>
                                );
                            }}
                        />


                    </div>

                    {/* <div className="input-add-user margin-input mt-[15px] d-flex flex-column">
                        <p>メールアドレス</p>
                        <input
                            autoComplete="off"
                            type="text"
                            className="worker-create-input"
                            {...register(
                                'email',
                                createWorkerValidation(t).email(),
                            )}
                        />
                        {errors.email && (
                            <span className="error">
                                {errors.email.message?.toString()}
                            </span>
                        )}
                    </div> */}

                    <div className="input-add-user margin-input mt-[15px] d-flex flex-column">
                        <Controller
                            name="personal_number"
                            control={control}
                            render={({ field }) => {
                                const { name, onChange, onBlur, ref } =
                                    register(
                                        'personal_number',
                                        createWorkerValidation(t).phone(),
                                    );
                                return (
                                    <div className="d-flex flex-column w-full">
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            個人番号
                                        </p>
                                        <input
                                            maxLength={MAX_LENGTH}
                                            {...register('personal_number')}
                                            onBlur={(evt) => {
                                                setValue('personal_number', fullWidthNumConvert(evt.target.value));
                                            }}
                                            className="worker-create-input"
                                            value={field.value}
                                            onChange={(evt) => {
                                                let stringWithoutCommas = evt.target.value.replace(/,/g, '',);
                                                if (stringWithoutCommas.match('^[0-9０-９]+$',) || stringWithoutCommas === '') {
                                                    if (stringWithoutCommas.length <= MAX_LENGTH) {
                                                        const newVal = fullWidthNumConvert(stringWithoutCommas,);
                                                        field.onChange(stringWithoutCommas,);
                                                        setValue('personal_number', stringWithoutCommas,);
                                                    }
                                                }
                                            }}
                                        />
                                        {errors.personal_number && (
                                            <span className="error text-xs tracking-tight mt-1">
                                                {errors.personal_number.message?.toString()}
                                            </span>
                                        )}
                                    </div>
                                );
                            }}
                        />
                        {/* <input
                            autoComplete="off"
                            type="text"
                            className="worker-create-input"
                            {...register(
                                'personal_number',
                                createWorkerValidation(t).phone(),
                            )}
                        />
                        {errors.personal_number && (
                            <span className="error">
                                {errors.personal_number.message?.toString()}
                            </span>
                        )} */}
                    </div>
                </div>

                <StyledFormGroup className="col-span-4 mt-[15px]">
                    <StyledFormGroupLabel>口座情報</StyledFormGroupLabel>
                    <div className="grid grid-cols-3 gap-4">
                        <div className="input-add-user margin-input d-flex flex-column">
                            <p> 金融機関名</p>
                            <input
                                autoComplete="off"
                                type="text"
                                className="worker-create-input"
                                {...register(
                                    'bank_name',
                                )}
                                onBlur={handleValidateWhitespace}
                                maxLength={MAX_LENGTH}
                            />
                            {errors.bank_name && (
                                <span className="error">
                                    {errors.bank_name.message?.toString()}
                                </span>
                            )}


                        </div>
                        <div className="input-add-user margin-input d-flex flex-column">
                            <p> 支店名</p>
                            <input
                                autoComplete="off"
                                type="text"
                                className="worker-create-input"
                                {...register(
                                    'branch_name',
                                )}
                                onBlur={handleValidateWhitespace}
                                maxLength={MAX_LENGTH}
                            />
                            {errors.branch_name && (
                                <span className="error">
                                    {errors.branch_name.message?.toString()}
                                </span>
                            )}
                        </div>
                        <Controller
                            name="account_type"
                            control={control}
                            render={({ field }) => (
                                <div className='!w-[100%] !h-[40px]'>
                                    <p className="text-[14px] mb-[4px] font-medium ">
                                        口座の種類
                                    </p>
                                    <Select
                                        options={BANK_ACCOUNT_TYPES}
                                        {...register(
                                            'account_type'
                                        )}
                                        value={field.value}
                                        onChange={(value) => {
                                            field.onChange(value)
                                        }}
                                        className='!w-[100%] !h-[40px]'
                                    />
                                    <br />
                                    {errors.account_type && (
                                        <span className="error">
                                            {errors.account_type.message?.toString()}
                                        </span>
                                    )}
                                </div>

                            )}
                        />
                        <div className="input-add-user margin-input d-flex flex-column">
                            <p> 口座名義人（漢字）</p>
                            <input
                                autoComplete="off"
                                type="text"
                                className="worker-create-input"
                                {...register(
                                    'account_holder_kanji',
                                )}
                                onBlur={handleValidateWhitespace}
                                maxLength={MAX_LENGTH}
                            />
                            {errors.account_holder_kanji && (
                                <span className="error">
                                    {errors.account_holder_kanji.message?.toString()}
                                </span>
                            )}


                        </div>
                        <div className="input-add-user margin-input d-flex flex-column">
                            <p> 口座名義人（全角カナ）</p>
                            <input
                                autoComplete="off"
                                type="text"
                                className="worker-create-input"
                                {...register(
                                    'account_holder_kana',
                                    createWorkerValidation(t).bank_kana()
                                )}
                                onBlur={handleValidateWhitespace}
                                maxLength={MAX_LENGTH}
                            />
                            {errors.account_holder_kana && (
                                <span className="error">
                                    {errors.account_holder_kana.message?.toString()}
                                </span>
                            )}
                        </div>

                        <div className="input-add-user margin-input d-flex flex-column">
                            <p> 口座番号</p>
                            <Controller
                                name="account_number"
                                control={control}
                                render={({ field }) => {
                                    const { name, onChange, onBlur, ref } =
                                        register(
                                            'account_number',
                                            createWorkerValidation(t).phone(),
                                        );
                                    return (
                                        <div className="d-flex flex-column w-full">
                                            <input
                                                maxLength={MAX_LENGTH}
                                                {...register('account_number')}
                                                onBlur={(evt) => {
                                                    setValue('account_number', fullWidthNumConvert(evt.target.value));
                                                }}
                                                className="worker-create-input"
                                                value={field.value}
                                                onChange={(evt) => {
                                                    let stringWithoutCommas = evt.target.value.replace(/,/g, '',);
                                                    if (stringWithoutCommas.match('^[0-9０-９]+$',) || stringWithoutCommas === '') {
                                                        if (stringWithoutCommas.length <= MAX_LENGTH) {
                                                            const newVal = fullWidthNumConvert(stringWithoutCommas,);
                                                            field.onChange(stringWithoutCommas,);
                                                            setValue('account_number', stringWithoutCommas,);
                                                        }
                                                    }
                                                }}
                                            />
                                            {errors.account_number && (
                                                <span className="error text-xs tracking-tight mt-1">
                                                    {errors.account_number.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    );
                                }}
                            />
                            {/* <input
                                autoComplete="off"
                                type="text"
                                className="worker-create-input"
                                {...register(
                                    'account_number',
                                )}
                                onBlur={handleValidateWhitespace}
                                maxLength={MAX_LENGTH}
                            />
                            {errors.account_number && (
                                <span className="error">
                                    {errors.account_number.message?.toString()}
                                </span>
                            )} */}
                        </div>
                    </div>
                </StyledFormGroup>
                <StyledFormGroup className="col-span-4 mt-4">
                    <StyledFormGroupLabel>緊急連絡先</StyledFormGroupLabel>
                    <div className="grid grid-cols-3 gap-4">
                        <div className="input-add-user margin-input d-flex flex-column">
                            <p> 名前</p>
                            <input
                                autoComplete="off"
                                type="text"
                                className="worker-create-input"
                                {...register(
                                    'emergency_contact_name',
                                )}
                                onBlur={handleValidateWhitespace}
                                maxLength={MAX_LENGTH}
                            />
                            {errors.emergency_contact_name && (
                                <span className="error">
                                    {errors.emergency_contact_name.message?.toString()}
                                </span>
                            )}
                        </div>
                        <Controller
                            name="emergency_contact_relationship"
                            control={control}
                            render={({ field }) => (
                                <div className='!w-[100%] !h-[40px]'>
                                    <p className="text-[14px] mb-[4px] font-medium ">
                                        続柄
                                    </p>
                                    <Select
                                        options={RELATIONSHIP_OPTIONS}
                                        {...register(
                                            'emergency_contact_relationship'
                                        )}
                                        value={field.value}
                                        onChange={(value) => {
                                            field.onChange(value)
                                        }}
                                        className='!w-[100%] !h-[40px]'
                                    />
                                    <br />
                                    {errors.emergency_contact_relationship && (
                                        <span className="error">
                                            {errors.emergency_contact_relationship.message?.toString()}
                                        </span>
                                    )}
                                </div>
                            )}
                        />
                        <div className="input-add-user margin-input d-flex flex-column">
                            <Controller
                                name="emergency_contact_phone"
                                control={control}
                                render={({ field }) => {
                                    const { name, onChange, onBlur, ref } =
                                        register(
                                            'emergency_contact_phone'
                                        );
                                    return (
                                        <div className="d-flex flex-column w-full">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                電話番号
                                            </p>
                                            <input
                                                maxLength={12}
                                                {...register('emergency_contact_phone')}
                                                onBlur={(evt) => {
                                                    setValue('emergency_contact_phone', fullWidthNumConvert(evt.target.value));
                                                }}
                                                className="worker-create-input"
                                                value={field.value}
                                                onChange={(evt) => {
                                                    let stringWithoutCommas = evt.target.value.replace(/,/g, '',);
                                                    if (stringWithoutCommas.match('^[0-9０-９]+$',) || stringWithoutCommas === '') {
                                                        if (stringWithoutCommas.length <= 12) {
                                                            const newVal = fullWidthNumConvert(stringWithoutCommas,);
                                                            field.onChange(stringWithoutCommas,);
                                                            setValue('emergency_contact_phone', stringWithoutCommas,);
                                                        }
                                                    }
                                                }}
                                            />
                                            {errors.emergency_contact_phone && (
                                                <span className="error text-xs tracking-tight mt-1">
                                                    {errors.emergency_contact_phone.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    );
                                }}
                            />

                        </div>
                    </div>
                </StyledFormGroup>
                <div className='grid grid-cols-3' >
                    <StyledFormGroup className="col-span-2 mt-4">
                        <StyledFormGroupLabel>ログイン情報</StyledFormGroupLabel>
                        <div className="grid grid-cols-2 gap-4">
                            <div className="input-add-user margin-input d-flex flex-column">
                                <p><RequireSign /> メール</p>
                                <input
                                    autoComplete="off"
                                    type="text"
                                    className="worker-create-input"
                                    {...register(
                                        'email', createWorkerValidation(t).email(),
                                    )}
                                    maxLength={MAX_LENGTH}
                                />
                                {errors.email && (
                                    <span className="error">
                                        {errors.email.message?.toString()}
                                    </span>
                                )}
                            </div>
                            {screenType === TYPE_OF_SCREEN.create ? <div className="input-add-user margin-input d-flex flex-column">
                                <p><RequireSign /> パスワード</p>
                                <input
                                    autoComplete="new-password"
                                    type="password"
                                    className="worker-create-input"

                                    {...register(
                                        'password', createWorkerValidation(t).password(),
                                    )}
                                    onBlur={handleValidateWhitespace}
                                    maxLength={MAX_LENGTH}
                                />
                                {errors.password && (
                                    <span className="error">
                                        {errors.password.message?.toString()}
                                    </span>
                                )}
                            </div> : <div className="input-add-user margin-input d-flex flex-column">
                                <p> パスワード</p>
                                <input
                                    autoComplete="new-password"
                                    type="password"
                                    className="worker-create-input"
                                    {...register(
                                        'password'
                                    )}
                                    onBlur={handleValidateWhitespace}
                                    maxLength={MAX_LENGTH}
                                />
                                {errors.password && (
                                    <span className="error">
                                        {errors.password.message?.toString()}
                                    </span>
                                )}
                            </div>}
                        </div>
                    </StyledFormGroup>
                </div>


                <Controller
                    control={control}
                    {...register('images', {
                        required:
                            '画像は最低1枚以上アップロードしてください。',
                    })}
                    render={({ field, fieldState }) => {
                        return (
                            <div className='mt-[15px]' >
                                <p><RequireSign /> アップロード</p>
                                <div className='flex flex-row gap-4'>
                                    <Upload
                                        className="!w-auto mb-[10px]"
                                        listType="picture-card"
                                        onPreview={handlePreview}
                                        onRemove={handleRemove}
                                        customRequest={dummyRequest}
                                        fileList={
                                            field.value && typeof field.value === 'string'
                                                ? [
                                                    {
                                                        uid: '-1',
                                                        name: 'image.png',
                                                        status: 'done',
                                                        url: field.value,
                                                    },
                                                ]
                                                : undefined
                                        }
                                        accept=".jpg,.jpeg,.png"
                                        beforeUpload={(file) => {
                                            field.onChange(file)

                                            return false
                                        }}
                                    >
                                        {field.value ? null : uploadButton}
                                    </Upload>
                                    <div className="max-w-[200px] w-full">
                                        ファイルを選択してください。 500KB以下のJPG、PNG応します。
                                    </div>
                                </div>
                                {errors.images && (
                                    <span className="error">
                                        {errors.images.message?.toString()}
                                    </span>
                                )}

                            </div>
                        )
                    }}
                />

                <div
                    className="flex flex-row justify-center create-button-worker"
                    style={{ marginTop: '14px' }}
                >
                    <button
                        className="modal-create-user-button1 !text-[#000000D9] !rounded-sm"
                        onClick={() => navigate('/workers')}
                        type="button"
                        style={{ border: '1px solid #E4E4E4' }}
                    >
                        キャンセル
                    </button>
                    <button
                        className="modal-create-user-button2 !rounded-sm"
                        // disabled={(newUser.email === "" || newUser.password === "" || newUser.password_confirmation === "" || newUser.worker_id === -1) ? true : false}
                        type="submit"
                    >
                        登録
                    </button>
                </div>
            </div>

        </form>
    );
};

const WorkerCreate: FC<ReceivedProps> = (props) => {
    return <WorkerCreateLayout {...useWorkerForm(props)} />;
};

export default WorkerCreate;
