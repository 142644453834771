import { FC, useRef } from 'react';
import { Controller } from 'react-hook-form';
import SelectSearch from 'react-select-search';
import useQuoteForm, { Props, ReceivedProps } from './hook';
import { PartnerFormStyles } from './styled';
import { handleFilter } from 'utils/helper/filterDropdown';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import { validZipCode } from 'utils/validation/registers/validator';
import { validateZipcode } from 'utils/helper/appHelper';
import { fullWidthNumConvert } from 'pages/invoice';
import PartnerListDetail from '../PartnerListDetail';
import partnerValidation from 'utils/validation/registers/partner';
import { MAX_LENGTH, TYPE_OF_SCREEN } from 'pages/project/projectPlan/projectTabs/constant';
import { formatZipcode } from 'utils/helper/workerHelper';

const PartnerFormLayout: FC<Props> = ({
    handleSubmit,
    submitData,
    control,
    setValue,
    register,
    errors,
    setError,
    navigate,
    handleValidateWhitespace,
    screenType,
    clearErrors,
    getValues,
    workersList,
    classificationOptions,
    id,
}) => {
    const { t } = useTranslation();
    const findZipcodeTimer = useRef<NodeJS.Timeout>();

    const parsePostalCode = (post_code: string) => {
        if (!post_code) {
            setError('post_code', {
                type: 'required',
                message: '郵便番号を入力してください。',
            });
            return;
        }

        const isZipcodeFormat = validateZipcode(post_code);
        if (!isZipcodeFormat) {
            setError('post_code', {
                type: 'validate',
                message:
                    '郵便番号は数字のみで、000-0000の形式でなければなりません。',
            });
            return;
        }

        if (findZipcodeTimer.current) {
            clearTimeout(findZipcodeTimer.current);
        }

        let foundAddress = { prefecture: '', city: '', area: '' };
        const postalCode = require('japan-postal-code');
        postalCode.get(post_code, function (address) {
            foundAddress = address;
        });

        findZipcodeTimer.current = setTimeout(() => {
            if (foundAddress) {
                setValue('city', foundAddress?.city);
                setValue('prefecture', foundAddress?.prefecture);
                setValue('address', foundAddress?.area);
                clearErrors('city');
                clearErrors('prefecture');
                clearErrors('address');
                clearErrors('post_code');
            } else {
                setValue('city', '');
                setValue('prefecture', '');
                setValue('address', '');
                setError('post_code', {
                    type: 'validate',
                    message: '入力された郵便番号に該当する住所が存在しません。',
                });
            }
        }, 1000);
    };
    return (
        <PartnerFormStyles className="p-[24px]">
            <div className="bg-[#fff] quote-form-container rounded-[4px]">
                <div className="p-[12px] pb-[36px]">
                    <form onSubmit={handleSubmit(submitData)}>
                        <div className="text-[18px] font-bold mb-[30px]">
                            顧客情報
                        </div>
                        <div className="flex gap-x-[10px] mb-[24px]">
                            <div className="w-[20%]">
                                <Controller
                                    name="company_id"
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                協力会社ID
                                            </p>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2 !bg-[#F5F5F5]"
                                                {...register('company_id')}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                                disabled
                                            ></input>
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="w-[30%]">
                                <Controller
                                    name="company_name"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">
                                                    *
                                                </span>
                                                協力会社名
                                            </p>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                                {...register(
                                                    'company_name',
                                                    partnerValidation(
                                                        t,
                                                    ).company_name(),
                                                )}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                                disabled={
                                                    screenType === 'detail'
                                                }
                                                maxLength={MAX_LENGTH}
                                            ></input>
                                            {errors.company_name && (
                                                <span className="error text-xs tracking-tight mt-1">
                                                    {errors.company_name.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="w-[50%]">
                                <Controller
                                    name="post_code"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field, fieldState }) => {
                                        const { name, onChange, onBlur, ref } =
                                            register('post_code', {
                                                required:
                                                    '郵便番号を入力してください。',
                                                validate: {
                                                    regex: validZipCode(
                                                        'zipcode',
                                                        t,
                                                    ),
                                                },
                                            });
                                        return (
                                            <div className="d-flex flex-column">
                                                <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                    <span className="text-red mr-1">
                                                        *
                                                    </span>
                                                    郵便番号
                                                </p>
                                                <div className="flex gap-[10px] w-full">
                                                    <Input
                                                        className="!w-[60%] !max-h-[38px]"
                                                        placeholder="000-0000"
                                                        {...register(
                                                            'post_code',
                                                        )}
                                                        onBlur={(evt) => {
                                                            let string = formatZipcode(evt.target.value)
                                                            if (
                                                                string.length > 4 &&
                                                                string.indexOf('-',) !== 3
                                                            ) {
                                                                let txt2 = string.replaceAll('-', '',);
                                                                txt2 = string.slice(0, 3,) + '-' + string.slice(3,);
                                                                setValue('post_code', txt2,);
                                                            } else {
                                                                setValue('post_code', string,);
                                                            }
                                                        }}
                                                        maxLength={8}
                                                        value={field.value}
                                                        onChange={(evt) => {
                                                            field.onChange(
                                                                evt.target
                                                                    .value,
                                                            );
                                                            setValue(
                                                                'post_code',
                                                                evt.target
                                                                    .value,
                                                            );
                                                            clearErrors(
                                                                'post_code',
                                                            );
                                                        }}
                                                    />

                                                    <button
                                                        onClick={() => {
                                                            if (
                                                                fieldState.error
                                                            ) {
                                                                return;
                                                            }
                                                            parsePostalCode(
                                                                getValues(
                                                                    'post_code',
                                                                ) as string,
                                                            );
                                                        }}
                                                        type="button"
                                                        className="border-[1px] rounded-[8px] text-[white] bg-[#215493] border-[#215493] !text-[16px] !p-0 !w-[40%] disabled:opacity-60"
                                                        // disabled={!editable}
                                                    >
                                                        住所自動入力
                                                    </button>
                                                </div>
                                                {errors.post_code && (
                                                    <span className="error">
                                                        {errors.post_code.message?.toString()}
                                                    </span>
                                                )}
                                            </div>
                                        );
                                    }}
                                />
                            </div>
                        </div>

                        <div className="flex gap-x-[10px] mb-[24px]">
                            <div className="w-[20%]">
                                <Controller
                                    name="city"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">
                                                    *
                                                </span>
                                                市/県
                                            </p>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2 !bg-[#F5F5F5]"
                                                {...register(
                                                    'city',
                                                    partnerValidation(t).city(),
                                                )}
                                                disabled
                                            ></input>
                                            {errors.city && (
                                                <span className="error text-xs tracking-tight mt-1">
                                                    {errors.city.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="w-[30%]">
                                <Controller
                                    name="prefecture"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">
                                                    *
                                                </span>
                                                区/郡
                                            </p>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2 !bg-[#F5F5F5]"
                                                {...register(
                                                    'prefecture',
                                                    partnerValidation(
                                                        t,
                                                    ).prefecture(),
                                                )}
                                                disabled
                                            ></input>
                                            {errors.prefecture && (
                                                <span className="error text-xs tracking-tight mt-1">
                                                    {errors.prefecture.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="w-[30%]">
                                <Controller
                                    name="address"
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                住所
                                            </p>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                                {...register('address')}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                                disabled={
                                                    screenType === 'detail'
                                                }
                                                maxLength={MAX_LENGTH}
                                            ></input>
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="w-[20%]">
                                <Controller
                                    name="fax_number"
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                FAX番号
                                            </p>
                                            <input
                                                autoComplete="off"
                                                type="number"
                                                className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                                {...register(
                                                    'fax_number',
                                                    partnerValidation(
                                                        t,
                                                    ).fax_number(),
                                                )}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                                disabled={
                                                    screenType === 'detail'
                                                }
                                            ></input>
                                            {errors.fax_number && (
                                                <span className="error text-xs tracking-tight mt-1">
                                                    {errors.fax_number.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                        </div>

                        <div className="flex gap-x-[10px] mb-[34px]">
                            <div className="flex flex-col w-[20%]">
                                <div className="input-add-user d-flex flex-column">
                                    <p style={{ marginTop: 0 }}>
                                        <span className="text-red mr-1">*</span>
                                        分類
                                    </p>
                                </div>
                                <Controller
                                    name="classification"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <SelectSearch
                                            options={classificationOptions}
                                            search
                                            filterOptions={handleFilter}
                                            {...field}
                                            emptyMessage={'該当なし'}
                                            placeholder=""
                                            disabled={screenType === 'detail'}
                                        />
                                    )}
                                />
                            </div>
                            <div className="w-[40%]">
                                <Controller
                                    name="industry_type"
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                業種
                                            </p>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                                {...register('industry_type')}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                                disabled={
                                                    screenType === 'detail'
                                                }
                                                maxLength={MAX_LENGTH}
                                            ></input>
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="w-[40%]">
                                <Controller
                                    name="introducer"
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                紹介者
                                            </p>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                                {...register('introducer')}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                                disabled={
                                                    screenType === 'detail'
                                                }
                                                maxLength={MAX_LENGTH}
                                            ></input>
                                        </div>
                                    )}
                                />
                            </div>
                        </div>

                        <div className="border border-solid border-[#000000] px-[12px] py-[12px]">
                            <p className="font-medium mt-[-26px] w-fit bg-[#ffffff] px-[8px] !mb-[6px]">
                                代表者情報
                            </p>
                            <div className="flex gap-x-[10px] mb-[12px]">
                                <div className="w-[25%]">
                                    <Controller
                                        name="name"
                                        rules={{ required: true }}
                                        control={control}
                                        render={({ field }) => (
                                            <div className="d-flex flex-column">
                                                <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                    <span className="text-red mr-1">
                                                        *
                                                    </span>
                                                    氏名
                                                </p>
                                                <input
                                                    autoComplete="off"
                                                    type="text"
                                                    className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                                    {...register(
                                                        'name',
                                                        partnerValidation(
                                                            t,
                                                        ).name(),
                                                    )}
                                                    onBlur={
                                                        handleValidateWhitespace
                                                    }
                                                    disabled={
                                                        screenType === 'detail'
                                                    }
                                                    maxLength={MAX_LENGTH}
                                                ></input>
                                                {errors.name && (
                                                    <span className="error text-xs tracking-tight mt-1">
                                                        {errors.name.message?.toString()}
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="w-[25%]">
                                    <Controller
                                        name="role"
                                        rules={{ required: true }}
                                        control={control}
                                        render={({ field }) => (
                                            <div className="d-flex flex-column">
                                                <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                    <span className="text-red mr-1">
                                                        *
                                                    </span>
                                                    役職
                                                </p>
                                                <input
                                                    autoComplete="off"
                                                    type="text"
                                                    className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                                    {...register(
                                                        'role',
                                                        partnerValidation(
                                                            t,
                                                        ).role(),
                                                    )}
                                                    onBlur={
                                                        handleValidateWhitespace
                                                    }
                                                    disabled={
                                                        screenType === 'detail'
                                                    }
                                                    maxLength={MAX_LENGTH}
                                                ></input>
                                                {errors.role && (
                                                    <span className="error text-xs tracking-tight mt-1">
                                                        {errors.role.message?.toString()}
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="w-[25%]">
                                    <Controller
                                        name="phone_number"
                                        rules={{ required: true }}
                                        control={control}
                                        render={({ field }) => (
                                            <div className="d-flex flex-column">
                                                <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                    <span className="text-red mr-1">
                                                        *
                                                    </span>
                                                    電話番号
                                                </p>
                                                <input
                                                    autoComplete="off"
                                                    type='number'
                                                    className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                                    {...register(
                                                        'phone_number',
                                                        partnerValidation(
                                                            t,
                                                        ).phone_number(),
                                                    )}
                                                    onBlur={(evt) => {
                                                        setValue('phone_number', fullWidthNumConvert(evt.target.value));
                                                    }}
                                                    value={field.value}
                                                    onChange={(evt) => {
                                                        let stringWithoutCommas = evt.target.value.replace(/,/g, '',);
                                                        if (stringWithoutCommas.match('^[0-9０-９]+$',) || stringWithoutCommas === '') {
                                                            if (stringWithoutCommas.length <= 12) {
                                                                field.onChange(stringWithoutCommas,);
                                                                setValue('phone_number', stringWithoutCommas,);
                                                            }
                                                        }
                                                    }}
                                                    disabled={
                                                        screenType === 'detail'
                                                    }
                                                    maxLength={12}
                                                ></input>
                                                {errors.phone_number && (
                                                    <span className="error text-xs tracking-tight mt-1">
                                                        {errors.phone_number.message?.toString()}
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="w-[25%]">
                                    <Controller
                                        name="email"
                                        rules={{ required: true }}
                                        control={control}
                                        render={({ field }) => (
                                            <div className="d-flex flex-column">
                                                <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                    <span className="text-red mr-1">
                                                        *
                                                    </span>
                                                    メール
                                                </p>
                                                <input
                                                    autoComplete="off"
                                                    type="text"
                                                    className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                                    {...register(
                                                        'email',
                                                        partnerValidation(
                                                            t,
                                                        ).email(),
                                                    )}
                                                    onBlur={
                                                        handleValidateWhitespace
                                                    }
                                                    disabled={
                                                        screenType === 'detail'
                                                    }
                                                ></input>
                                                {errors.email && (
                                                    <span className="error text-xs tracking-tight mt-1">
                                                        {errors.email.message?.toString()}
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mt-[16px]">
                            <Controller
                                name="comment"
                                control={control}
                                render={({ field }) => (
                                    <div className="d-flex flex-column">
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            コメント
                                        </p>
                                        <textarea
                                            autoComplete="off"
                                            className="rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                            {...register('comment')}
                                            onBlur={handleValidateWhitespace}
                                            disabled={screenType === 'detail'}
                                            rows={3}
                                        ></textarea>
                                    </div>
                                )}
                            />
                        </div>
                        <div className="mt-[16px]">
                            <div className="input-add-user d-flex flex-column">
                                <p style={{ marginTop: 0 }}>
                                    <span className="text-red mr-1">*</span>
                                    担当者
                                </p>
                            </div>
                            <Controller
                                name="staff_id"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <SelectSearch
                                        options={workersList}
                                        search
                                        {...register(
                                            'staff_id',
                                            partnerValidation(t).staff_id(),
                                        )}
                                        filterOptions={handleFilter}
                                        {...field}
                                        placeholder="担当者"
                                        disabled={screenType === 'detail'}
                                    />
                                )}
                            />
                            {errors.staff_id && (
                                <span className="error text-xs tracking-tight mt-1">
                                    {errors.staff_id.message?.toString()}
                                </span>
                            )}
                        </div>

                        <div className="text-lg flex flex-row flex-wrap gap-[10px] mt-[34px]">
                            <button
                                className="modal-create-user-button1 !text-[#000000D9] d-flex flex-row !rounded-[2px] !w-[146px]"
                                type="button"
                                onClick={() => navigate('/partners')}
                            >
                                キャンセル
                            </button>
                            {screenType !== TYPE_OF_SCREEN.detail && (
                                <button
                                    className="modal-create-user-button2 d-flex flex-row !rounded-[2px] !w-[146px]"
                                    type="submit"
                                >
                                    保存
                                </button>
                            )}
                        </div>
                    </form>
                </div>

                {screenType !== TYPE_OF_SCREEN.create && (
                    <>
                        <div className="w-full h-[24px] bg-[#E3E6E8]"></div>

                        <div className="p-[12px]">
                            <PartnerListDetail idCompany={id?.id} />
                        </div>
                    </>
                )}
            </div>
        </PartnerFormStyles>
    );
};

const PartnerForm: FC<ReceivedProps> = (props) => {
    return <PartnerFormLayout {...useQuoteForm(props)} />;
};

export default PartnerForm;
