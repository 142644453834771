import { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import departmentsService from 'services/departmentsService';
import PositionsService from 'services/positionService';
import workersService from 'services/wokersService';
import staffApi from 'services/staffApi';

export type ReceivedProps = Record<string, any>;

const useSchedules = (props: ReceivedProps) => {
    const { userData } = useSelector((state: RootState) => state.users);
    const [openScheduleFormDialog, setOpenScheduleFormDialog] =
        useState<boolean>(false);
    const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
        useState<boolean>(false);
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const [openPlanUpdateModal, setOpenPlanUpdateModal] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<number>(userData.worker ? 0 : 1);
    const [workerOptions, setWorkerOptions] = useState<
        {
            id: any;
            name: string;
        }[]
    >();
    const [positionOptions, setPositionOptions] = useState<
        {
            id: any;
            name: string;
        }[]
    >();
    const [departmentOptions, setDepartmentOptions] = useState<
        {
            id: any;
            name: string;
        }[]
    >();
    const [searchParam, setSearchParam] = useState<any>({});

    const getListWorkers = async (page = 1, per_page = 9999) => {
        const body = {
            page,
            per_page
        }
        try {
            const res = await staffApi.getStaffs(user?.access_token, body)
            if (res !== null && res !== undefined) {
                const data = res?.data?.staffs
                setWorkerOptions(data?.map((item) => ({
                    value: item.id,
                    name: item.name,
                    label: item.name,
                    id: item.id,
                })))

            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const fetchPositions = useCallback(async () => {
        try {
            const { positions } = await PositionsService.getListPositions({
                page: 1,
                per_page: 9999,
            });
            setPositionOptions(
                positions.map((item) => ({
                    id: item.id,
                    name: item.name,
                })),
            );
        } catch (error) {
            //
        }
    }, []);

    const fetchDepartments = useCallback(async () => {
        try {
            const { departments } =
                await departmentsService.getListDepartments();
            setDepartmentOptions(
                departments.map((item) => ({
                    id: item.id,
                    name: item.name,
                })),
            );
        } catch (error) {
            //
        }
    }, []);

    useEffect(() => {
        getListWorkers();
        // fetchPositions();
        // fetchDepartments();
    }, [getListWorkers]);

    return {
        ...props,
        openScheduleFormDialog,
        openDeleteConfirmationDialog,
        openPlanUpdateModal,
        activeTab,
        workerOptions,
        positionOptions,
        departmentOptions,
        searchParam,
        setSearchParam,
        setActiveTab,
        setOpenDeleteConfirmationDialog,
        setOpenScheduleFormDialog,
        setOpenPlanUpdateModal,
    };
};

export type Props = ReturnType<typeof useSchedules>;

export default useSchedules;
