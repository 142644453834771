import { ALL_CHARACTERS } from "pages/project/projectPlan/projectTabs/constant";

export const makeCode = (length) => {
    let result = '';
    const charactersLength = ALL_CHARACTERS.length;
    let counter = 0;
    while (counter < length) {
        result += ALL_CHARACTERS.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}